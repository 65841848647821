
import { Component, Vue, Prop } from 'vue-property-decorator'
import { FactDescription } from '@/interfaces/engine-exports'

/**
 * Renders nothing.
 */
@Component
export default class GroupRenderer extends Vue {
  @Prop()
  fact!: FactDescription

  render() {
    return null
  }
}
