
























































import { Component, Mixins, Watch } from 'vue-property-decorator'
import moment from 'moment'
import FieldMixin from '@/components/facts/FieldMixin.vue'
import vSelect from 'vue-select'

@Component({
  components: {
    vSelect
  }
})
export default class SplitDateFieldRenderer extends Mixins(FieldMixin) {
  private DATE_FORMAT = 'yyyy-MM-DD'
  private date: DateData = {
    year: null,
    month: null,
    day: null
  }

  mounted() {
    const value = this.fact.currentValue || this.fact.defaultValue || ''
    if (value) {
      const date = new Date(value)
      this.date.year = date.getFullYear()
      this.date.month = date.getMonth() + 1
      this.date.day = date.getDate()
    }
  }

  get min(): Date {
    if (this.config?.min) {
      return this.config.min()
    } else {
      return new Date(1900, 1, 1)
    }
  }

  get max(): Date {
    if (this.config?.max) {
      return this.config.max()
    } else {
      return new Date()
    }
  }

  get availableYearOptions(): SelectOption[] {
    const minYear = this.min.getFullYear()
    const maxYear = this.max.getFullYear()
    const availableYears = []

    for (let i = maxYear; i >= minYear; i--) {
      availableYears.push({ name: i.toString(), value: i })
    }
    return availableYears
  }

  @Watch('availableYearOptions')
  private clearYearIfNeeded() {
    if (this.date.year && !this.availableYearOptions.map(option => option.value).includes(this.date.year)) {
      this.date.year = null
    }
  }

  get availableMonthOptions(): SelectOption[] {
    const minMonth = 1
    let maxMonth = 12
    const availableMonths = []

    // If year is selected as maximum year, only months from January to maximum months are available
    if (this.date.year && this.date.year >= this.max.getFullYear()) {
      maxMonth = this.max.getMonth() + 1
    }

    for (let i = minMonth; i <= maxMonth; i++) {
      availableMonths.push({ name: i.toString(), value: i })
    }
    return availableMonths
  }

  @Watch('availableMonthOptions')
  private clearMonthIfNeeded() {
    if (this.date.month && !this.availableMonthOptions.map(option => option.value).includes(this.date.month)) {
      this.date.month = null
    }
  }

  get availableDayOptions(): SelectOption[] {
    const minDay = 1
    let maxDay = 31
    const availableDays = []

    // If year and month are selected
    if (this.date.year && this.date.month) {
      // and both are corresponding to maximum year/month values, only days from 1st to maximum day are available
      if (this.date.year >= this.max.getFullYear() && this.date.month >= this.max.getMonth() + 1) {
        maxDay = this.max.getDate()
      } else { // and their values are not corresponding to maximum year/month, only days available in given month/year are available
        // month index is representing next month and 0 (day) is representing one day before start of selected month
        // if September is selected -> month === 9 (in our data) -> in Date object, month with index 9 is October -> (X, 9, 1) would be 1.10.X, so (X, 9, 0) is one day before, so it will be 30.8.X -> day will be 30
        maxDay = new Date(this.date.year, this.date.month, 0).getDate()
      }
    }

    for (let i = minDay; i <= maxDay; i++) {
      availableDays.push({ name: i.toString(), value: i })
    }
    return availableDays
  }

  @Watch('availableDayOptions')
  private clearDayIfNeeded() {
    if (this.date.day && !this.availableDayOptions.map(option => option.value).includes(this.date.day)) {
      this.date.day = null
    }
  }

  onYearSelected(value: string) {
    this.date.year = Number(value)
    this.onDateUpdated()
  }

  onMonthSelected(value: string) {
    this.date.month = Number(value)
    this.onDateUpdated()
  }

  onDaySelected(value: string) {
    this.date.day = Number(value)
    this.onDateUpdated()
  }

  onDateUpdated() {
    let value = ''
    let isValid = false
    if (this.date.year && this.date.month && this.date.day) {
      const date = moment({ year: this.date.year, month: this.date.month - 1, day: this.date.day })
      isValid = date.isValid() && date.isBetween(moment(this.min), moment(this.max), undefined, '[]')

      if (isValid) {
        value = date.format(this.DATE_FORMAT).toString()
      }
    }

    this.fireFactChange({
      id: this.fact.id,
      value
    })
    this.fireFactValidation({
      id: this.fact.id,
      valid: isValid
    })
  }
}

interface SelectOption {
  value: number;
  name: string;
}

interface DateData {
  year: number | null,
  month: number | null,
  day: number | null
}
