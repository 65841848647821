



























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { FactDescription } from '@/interfaces/engine-exports'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'

@Component({
  name: 'BasicInput'
})
export default class BasicInput extends Vue {
  @Prop()
  fact!: FactDescription

  @Prop()
  label?: string

  @Prop({ default: 'text' }) type!: string

  @Prop()
  placeholder?: string

  @Prop({ default: '' })
  private value!: string

  @Prop({ default: '' })
  private name?: string

  @Prop({ default: false })
  private disabled?: boolean

  @Prop()
  private icon?: string

  @Prop()
  private validator?: RegExp

  /**
   * If this property is set to false
   * component will only trigger fact-change
   * when onchange occurs. If it is set to
   * true (default), then it will trigger on
   * every key press.
   */
  @Prop({ default: true })
  private streaming!: boolean

  get isDisabled() {
    return this.fact?.readonly || this.disabled
  }

  get getName() {
    return this.name || this.fact?.id || ''
  }

  private _current = ''

  get current() {
    return this.fact?.currentValue || this.fact?.defaultValue || this.value
  }

  set current(value: string) {
    this._current = value
    if (this.validator && !this.validator.test(value)) {
      return
    }
    this.$emit('input', value)
    if (this.streaming) {
      this.factChange({
        id: this.fact?.id,
        value
      } as FactChangeEvent)
    }
  }

  onChange() {
    if (!this.streaming) {
      this.factChange({
        id: this.fact?.id,
        value: this._current
      } as FactChangeEvent)
    }
  }

  @Emit('fact-change')
  factChange(evt: FactChangeEvent) {
    return evt
  }

  getLabel() {
    return this.label || this.fact?.name || ''
  }

  getPlaceholder() {
    return this.placeholder || this.fact?.name || this.label
  }

  // MOCKUP: temporary display icons
  getIcon() {
    switch (this.fact?.id) {
      case 'applicant.firstname':
      case 'applicant.lastname':
        return 'profile'
      case 'COMPONENT:base.deviceregistration.photofront.serialbody':
      case 'COMPONENT:base.deviceregistration.photofront.serialjoyconleft':
      case 'COMPONENT:base.deviceregistration.photofront.serialjoyconright':
        return 'scan'
      default:
        return this.icon
    }
  }
}
