































import { Component, Mixins } from 'vue-property-decorator'
import DetailField from '@/components/DetailField.vue'
import ExternalLinkModal from '@/components/modals/external-links/ExternalLinkModal.vue'
import FieldMixin from '@/components/facts/FieldMixin.vue'
import constants from '@/constants'
@Component({
  components: {
    DetailField,
    ExternalLinkModal
  }
})
export default class ShippingCompanyRenderer extends Mixins(FieldMixin) {
  modalName = constants.modalNames.SHIPPING_COMPANY_MODAL

  onCancelModal () {
    this.$modal.hide(this.modalName)
  }

  goToShippingCompany() {
    this.$modal.show(constants.modalNames.SHIPPING_COMPANY_MODAL)
  }
}
