







import { Component, Vue } from 'vue-property-decorator'
import LargeText from '@/components/facts/LargeText.vue'
import FieldMixin from '@/components/facts/FieldMixin.vue'

@Component({
  components: { LargeText },
  mixins: [FieldMixin]
})
export default class LargeTextRenderer extends Vue {
}
