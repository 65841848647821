import { blobToArrayBuffer } from '@/services/functions'

// Removes EXIF tags from JPEG images
// Based on: https://jsfiddle.net/mowglisanu/frhwm2xe/3/

interface Piece {
  recess: number
  offset: number
}

const JPEG_MARKER = 0xffd8
const APP1_MARKER = 0xffe1
const SOS_MARKER = 0xffda

// Removes EXIF tags from a JPEG image
export function removeExifTagsFromJpeg(buffer: ArrayBuffer): Promise<ArrayBuffer> {
  const dv = new DataView(buffer)
  let offset = 0
  let recess = 0
  const pieces: Piece[] = []
  if (dv.getUint16(offset) === JPEG_MARKER) {
    offset += 2
    let bytePortion = dv.getUint16(offset)
    offset += 2
    while (offset < dv.byteLength) {
      if (bytePortion === APP1_MARKER) {
        pieces.push({ recess: recess, offset: offset - 2 })
        recess = offset + dv.getUint16(offset)
      } else if (bytePortion === SOS_MARKER) {
        break
      }
      offset += dv.getUint16(offset)
      bytePortion = dv.getUint16(offset)
      offset += 2
    }
    if (pieces.length > 0) {
      const newPieces = pieces.map(p => buffer.slice(p.recess, p.offset))
      newPieces.push(buffer.slice(recess))
      return blobToArrayBuffer(new Blob(newPieces, { type: 'image/jpeg' }))
    } else {
      // No EXIF tags
      return Promise.resolve(buffer)
    }
  } else {
    throw new Error('Not a JPEG image')
  }
}
