































import { Component, Mixins, Watch } from 'vue-property-decorator'
import picker from 'vue2-datepicker'
import moment from 'moment'
import FieldMixin from '@/components/facts/FieldMixin.vue'
// @ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

@Component({
  components: {
    picker,
    DatePicker
  }
})
export default class DateFieldRenderer extends Mixins(FieldMixin) {
  private DATE_FORMAT = 'yyyy-MM-DD'
  private currentValue = ''

  mounted() {
    this.currentValue = this.fact.currentValue || this.fact.defaultValue || ''
  }

  @Watch('currentValue')
  private updateValue(value: string) {
    this.validate(value)
    this.fireFactChange({
      id: this.fact.id,
      value: value || ''
    })
  }

  validate(value: string | null): boolean {
    let valid = false
    if (value) {
      const date = moment(value)
      if (!date.isValid()) {
        valid = false
      } else {
        valid = !this.disabledDate(date.toDate())
      }
    }
    this.error = !valid
    this.fireFactValidation({
      id: this.fact.id,
      valid
    })
    return valid
  }

  get min(): string {
    if (this.config?.min) {
      return this.format(this.config.min())
    } else {
      return ''
    }
  }

  get max(): string {
    if (this.config?.max) {
      return this.format(this.config.max())
    } else {
      return ''
    }
  }

  disabledDate(date: Date): boolean {
    if (this.config?.min && this.config.min() > date) {
      return true
    }
    if (this.config?.max && this.config.max() < date) {
      return true
    }
    return false
  }

  format(date: Date | moment.Moment | string): string {
    return moment(date).format(this.DATE_FORMAT).toString()
  }

  private selectDateInput(showPopover: () => void) {
    showPopover()
    const datePicker = this.$refs.datePicker as HTMLInputElement
    datePicker.focus()
  }

  private doBlurUpdate(inputValue: string, updateValue: (value: string) => void) {
    updateValue(moment(inputValue, 'YYYY年MM月DD日').format('YYYY-MM-DD'))
  }
}
