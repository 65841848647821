
import { Component, Mixins } from 'vue-property-decorator'
import FieldMixin from '@/components/facts/FieldMixin.vue'

@Component
export default class TextRenderer extends Mixins(FieldMixin) {
  get value(): string {
    if (this.config?.value) {
      return this.config.value(this.fact, this.$t.bind(this))
    } else {
      // display the option name instead, if formatType is list and readonly
      if (this.readonly && this.fact.formatType === 'list') {
        const selectedOption = this.fact.options.find((option: { name: string, value: string }) => option.value === this.fact.currentValue)
        return selectedOption?.name || ''
      } else {
        return this.fact.currentValue || this.fact.defaultValue || ''
      }
    }
  }
}
