















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import TextInput from '@/components/TextInput.vue'
import { FactDescription } from '@/interfaces/engine-exports'
import { FactConfig, ValidationResult } from '@/services/Renderer'
import { FactValidationEvent } from '@/interfaces/FactValidationEvent'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'

@Component({
  components: {
    TextInput
  }
})
export default class SerialNumberFieldRenderer extends Vue {
  @Prop() fact!: FactDescription
  @Prop() config?: FactConfig
  @Prop() readonly!: boolean

  protected error = false

  get label(): string {
    if (this.config?.label) {
      return this.$t(this.config.label).toString()
    } else {
      return this.fact.name
    }
  }

  get value(): string {
    if (this.config?.value) {
      return this.config.value(this.fact, this.$t.bind(this))
    } else {
      return this.fact.currentValue || this.fact.defaultValue || ''
    }
  }

  get icon(): string | undefined {
    return this.config?.icon
  }

  mounted() {
    if (this.value) {
      this.validateInput(this.value)
      this.validateChange(this.value)
    }
  }

  onInput(value: string) {
    this.validateInput(value)
    this.fireFactChangeEvents(value)
  }

  onChange(value: string) {
    this.validateChange(value)
    if (this.config?.transformValue) {
      value = this.config.transformValue(value)
    }
    this.fireFactChangeEvents(value)
  }

  onValidation(valid: boolean) {
    this.fireFactValidation({
      id: this.fact.id,
      valid
    })
  }

  @Emit('fact-validation')
  fireFactValidation(evt: FactValidationEvent) {
    return evt
  }

  @Emit('fact-change')
  fireFactChange(evt: FactChangeEvent) {
    return evt
  }

  protected validateInput(value: string): ValidationResult {
    let validationResult: ValidationResult = { valid: true }

    if (this.config?.validate) {
      validationResult = this.config.validate(value, this.fact)
    }

    this.fireFactValidation({
      id: this.fact.id,
      valid: validationResult.valid,
      message: validationResult.message
    })
    this.error = !validationResult.valid
    return validationResult
  }

  protected validateChange(value: string): ValidationResult {
    let validationResult: ValidationResult = { valid: true }

    if (this.config?.validateChange) {
      validationResult = this.config.validateChange(value, this.fact, this)

      this.fireFactValidation({
        id: this.fact.id,
        valid: validationResult.valid,
        message: validationResult.message
      })
      this.error = !validationResult.valid
    }
    return validationResult
  }

  private fireFactChangeEvents(value: string) {
    this.fireFactChange({
      id: this.fact.id,
      value: value
    })
  }
}
