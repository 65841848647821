














import { Component, Vue, Prop } from 'vue-property-decorator'
import DeviceOption from '@/components/DeviceOption.vue'
import { FactDescription } from '@/interfaces/engine-exports'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
@Component({
  components: {
    DeviceOption
  }
})
export default class Coverage extends Vue {
  @Prop() fact!: FactDescription

  selectOption (value: string) {
    this.$emit('fact-change', {
      id: this.fact.id,
      value,
      next: true
    } as FactChangeEvent)
  }
}
