import { FactDescription } from '@/interfaces/engine-exports'
import { FactConfigs } from '@/config/FactConfigs'
import DeviceOptionRenderer from '@/components/facts/renderers/DeviceOptionRenderer.vue'
import PlanOptionRenderer from '@/components/facts/renderers/PlanOptionRenderer.vue'
import GroupRenderer from '@/components/facts/renderers/GroupRenderer.vue'
import EmailInputRenderer from '@/components/facts/renderers/EmailInputRenderer.vue'
import ImageUpload from '@/components/facts/ImageUpload.vue'
import MultiLine from '@/components/facts/Multiline.vue'
import RadioButtonGroup from '@/components/facts/RadioButtonGroup.vue'
import Select from '@/components/facts/Select.vue'
import BasicInput from '@/components/facts/BasicInput.vue'
import ListRenderer from '@/components/facts/renderers/ListRenderer.vue'
import DetailFieldRenderer from '@/components/facts/renderers/DetailFieldRenderer.vue'
import JoyConDetailFieldRenderer from '@/components/facts/renderers/JoyConDetailFieldRenderer.vue'
import ListDetailFieldRenderer from '@/components/facts/renderers/ListDetailFieldRenderer.vue'
import ClaimStatusFieldRenderer from '@/components/facts/renderers/ClaimStatusFieldRenderer.vue'
import ShippingCompanyRenderer from '@/components/facts/renderers/ShippingCompanyRenderer.vue'
import TextRenderer from '@/components/facts/renderers/TextRenderer.vue'
import PostalCodeRenderer from '@/components/facts/renderers/PostalCodeRenderer.vue'
import InfoRenderer from '@/components/facts/renderers/InfoRenderer.vue'
import SerialNumberFieldRenderer from '@/components/facts/renderers/SerialNumberFieldRenderer.vue'
import MultilineRenderer from '@/components/facts/renderers/MultilineRenderer.vue'
import DateFieldRenderer from '@/components/facts/renderers/DateFieldRenderer.vue'
import SplitDateFieldRenderer from '@/components/facts/renderers/SplitDateFieldRenderer.vue'
import LargeTextRenderer from '@/components/facts/renderers/LargeTextRenderer.vue'
import DeviceNicknameRenderer from '@/components/facts/renderers/DeviceNicknameRenderer.vue'
import DeviceTypeRenderer from '@/components/facts/renderers/DeviceTypeRenderer.vue'
import ClaimDetailOtherRenderer from '@/components/facts/renderers/ClaimDetailOtherRenderer.vue'

/**
 * Contains rules on picking fact renderers.
 */
const ComponentMap: any = {
  LIST: {
    DEFAULT: 'ListRenderer'
  },
  GROUP: {
    DEFAULT: 'GroupRenderer'
  },
  BOOLEAN: {
    DEFAULT: 'Checkbox',
    YESNO: 'RadioButtonGroup'
  },
  MULTILINE_TEXT: {
    DEFAULT: 'MultilineRenderer'
  },
  TEXT: {
    DEFAULT: 'TextRenderer'
  },
  NUMBER: {
    DEFAULT: 'TextRenderer'
  },
  EMAIL: {
    DEFAULT: 'EmailInputRenderer'
  },
  DATE: {
    DEFAULT: 'DateFieldRenderer'
  },
  FILE_REFERENCE: {
    DEFAULT: 'ImageUpload'
  },
  PHONE_NUMBER: {
    DEFAULT: 'TextRenderer'
  },
  INFO: {
    DEFAULT: 'InfoRenderer'
  }
}

function getRenderer(fact: FactDescription, context?: string) {
  if (context === 'claimDetail') {
    switch (fact.id) {
      case 'device.serialjoyconleft':
      case 'device.serialjoyconright':
        return 'JoyConDetailFieldRenderer'
      case 'claim.issue.other':
        return 'ClaimDetailOtherRenderer'
    }
  }
  if (context === 'appForm') {
    switch (fact.id) {
      case 'applicant.dob':
        return 'SplitDateFieldRenderer'
    }
  }
  switch (fact.id) {
    case 'COMPONENT:base.coverage.devicetype':
      return 'DeviceOptionRenderer'
    case 'COMPONENT:base.plan.plan':
      return 'PlanOptionRenderer'
    case 'claimant.postalcode':
      return 'PostalCodeRenderer'
    case 'certificate.warranty':
    case 'certificate.proofofpurchase':
    case 'device.front':
    case 'device.front.pic2':
    case 'device.front.pic3':
    case 'device.back':
      return 'ImageUpload' // temporary: unknown fact type for uploader
    case 'COMPONENT:base.deviceregistration.photofront.serialjoyconright':
    case 'COMPONENT:base.deviceregistration.photofront.serialjoyconleft':
    case 'COMPONENT:base.deviceregistration.photofront.serialbody':
      return 'SerialNumberFieldRenderer'
    case 'COMPONENT:base.deviceregistration.nickname':
      return 'DeviceNicknameRenderer'
    default:
      return (
        ComponentMap[fact.type.toString()][
          fact.formatType.toString().toUpperCase()
        ] || ComponentMap[fact.type.toString()].DEFAULT
      )
  }
}

function getConfig(
  fact: FactDescription,
  context = 'default'
): FactConfig | undefined {
  const config = FactConfigs[context][fact.id]
  return config || FactConfigs.default[fact.id]
}

function addConfig(context: string, key: string, config: FactConfig) {
  FactConfigs[context][key] = config
}

function removeConfig(context: string, key: string) {
  delete FactConfigs[context][key]
}

export const Renderer = {
  get: getRenderer,
  config: {
    get: getConfig,
    add: addConfig,
    remove: removeConfig
  },
  DEFAULT: 'TextRenderer',
  components: {
    ClaimDetailOtherRenderer,
    DeviceOptionRenderer,
    DeviceTypeRenderer,
    DeviceNicknameRenderer,
    GroupRenderer,
    PlanOptionRenderer,
    ImageUpload,
    MultiLine,
    RadioButtonGroup,
    Select,
    EmailInputRenderer,
    BasicInput,
    ListRenderer,
    DetailFieldRenderer,
    JoyConDetailFieldRenderer,
    ListDetailFieldRenderer,
    ClaimStatusFieldRenderer,
    ShippingCompanyRenderer,
    InfoRenderer,
    MultilineRenderer,
    LargeTextRenderer,
    TextRenderer,
    DateFieldRenderer,
    SplitDateFieldRenderer,
    SerialNumberFieldRenderer,
    PostalCodeRenderer
  }
}

export interface FnValue {
  (fact: FactDescription, t: Function): string
}

export interface FnTransform {
  (value: string): string
}

interface FnValidate {
  (value: any, fact: FactDescription): ValidationResult
}

export interface ValidationResult {
  valid: boolean
  message?: string
}
interface FnChangeValidate {
  (value: any, fact: FactDescription, component: any): ValidationResult
}

interface FnDate {
  (): Date
}

export interface FactConfigDef {
  [key: string]: {
    [key: string]: FactConfig
  }
}

export interface FactConfig {
  label?: string
  value?: FnValue
  transformValue?: FnTransform
  placeholder?: string
  icon?: string
  validate?: FnValidate
  validateChange?: FnChangeValidate
  min?: FnDate
  max?: FnDate
  readonly?: boolean
}
