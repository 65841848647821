












































import { Component, Prop, Vue } from 'vue-property-decorator'
import uploader from 'vue-upload-component'
import InfoModal from '@/components/modals/InfoModal.vue'
import { InfoModalDescription } from '@/interfaces'
import { FactDescription } from '@/interfaces/engine-exports'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import { OCRMap, Shift } from '@/services/Shift'
import { Config } from '@/config'
import { getModalContent } from '@/constants/modalContents'
import { blobToArrayBuffer, downscaleImage, isMediaDeviceSupport } from '@/services/functions'
import { removeExifTagsFromJpeg } from '@/services/exif'
import { FactConfig } from '@/services/Renderer'
import { filetoDataURL } from 'image-conversion'
import constants from '@/constants'

interface ImageAsset {
  uuid: string;
  name: string;
  dataUri: string;
  contentType: string;
  blob?: Blob;
}

@Component({
  components: {
    uploader,
    InfoModal
  }
})
export default class ImageUpload extends Vue {
  @Prop()
  fact!: FactDescription

  @Prop()
  config?: FactConfig

  @Prop({ default: 'image/*' })
  accept!: string

  @Prop({ default: 'environment' })
  capture!: 'user' | 'environment'

  private files: File[] = []

  private UPLOAD_WIDTH = 2000
  private ORIGINAL_WIDTH = 1000
  private THUMBNAIL_WIDTH = 110
  private MAX_UPLOAD_SIZE = 512000
  private MAX_UPLOAD_SIZE_KB = 500

  private PHOTO_FRONT_FACT_ID = 'COMPONENT:base.deviceregistration.photofront'
  private PHOTO_BACK_FACT_ID = 'COMPONENT:base.deviceregistration.photoback'
  private WARRANTY_CARD_FACT_ID = 'COMPONENT:base.guarantee.warrantycard'
  private RECEIPT_FACT_ID = 'COMPONENT:base.guarantee.receipt'

  private thumbnail: ImageAsset = {
    uuid: '',
    name: '',
    dataUri: '',
    contentType: ''
  }

  private original: ImageAsset = {
    uuid: '',
    name: '',
    contentType: '',
    dataUri: '',
    blob: undefined
  }

  get isOcr() {
    return Config.ocr.ocrFacts.includes(this.fact.id)
  }

  get tooltipTextKey(): string | null {
    switch (this.fact.id) {
      case this.PHOTO_FRONT_FACT_ID:
        return 'appForm.tooltip.photofront'
      case this.PHOTO_BACK_FACT_ID:
        return 'appForm.tooltip.photoback'
      case this.WARRANTY_CARD_FACT_ID:
        return 'proofCert.tooltip.warrantycard'
      case this.RECEIPT_FACT_ID:
        return 'proofCert.tooltip.receipt'
      default:
        return null
    }
  }

  get description(): InfoModalDescription | null {
    switch (this.fact.id) {
      case this.PHOTO_FRONT_FACT_ID:
        return getModalContent('photofront', this.$t.bind(this))
      case this.PHOTO_BACK_FACT_ID:
        return getModalContent('photoback', this.$t.bind(this))
      case this.WARRANTY_CARD_FACT_ID:
        return getModalContent('warrantycard', this.$t.bind(this))
      case this.RECEIPT_FACT_ID:
        return getModalContent('receipt', this.$t.bind(this))
      default:
        return null
    }
  }

  getLabel() {
    if (this.config?.label) {
      return this.$t(this.config.label).toString()
    } else {
      return this.fact.name
    }
  }

  async getThumbnail() {
    if (this.thumbnail.dataUri) return // already have it

    if (this.fact.currentValue) {
      const thumbDataUri = this.getImageFromCache(
        `thumb-${this.fact.currentValue}`
      )
      const origDataUri = this.getImageFromCache(
        `orig-${this.fact.currentValue}`
      )
      if (thumbDataUri && origDataUri) {
        this.thumbnail.dataUri = thumbDataUri
        this.original.dataUri = origDataUri
      } else {
        // download original
        try {
          const photo = await Shift.getCMSContentByFactId(
            this.fact.currentValue
          )
          Promise.all([
            this.generateImageAssetFromDataUri(
              photo.blob,
              photo.name,
              photo.contentType,
              this.THUMBNAIL_WIDTH
            ),
            this.generateImageAssetFromDataUri(
              photo.blob,
              photo.name,
              photo.contentType,
              this.ORIGINAL_WIDTH
            )
          ]).then(([thumbnail, original]) => {
            this.thumbnail = thumbnail
            this.original = original
          }).catch(e => {
            console.error(e)
          })
        } catch (e) {
          console.error(e)
        }
      }
    }
  }

  checkCameraPermission(callback: Function) {
    if (isMediaDeviceSupport()) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          if (stream.getVideoTracks().length > 0) {
            console.info('granted camera permission')
            callback()
          } else {
            console.info('no camera permission')
            this.$router.replace({ name: constants.routeNames.DENIED_CAMERA })
          }
        })
        .catch(() => {
          console.info('no camera permission')
          this.$router.replace({ name: constants.routeNames.DENIED_CAMERA })
        })
    }
  }

  // Silly way of ensuring image cache does not
  // get filled.
  private ensureCache() {
    const len = window.sessionStorage.length
    const matches: string[] = []
    for (let index = 0; index < len; index++) {
      const keyName = window.sessionStorage.key(index)
      if (
        keyName?.startsWith('thumb-') ||
        keyName?.startsWith('orig-')
      ) {
        matches.push(keyName)
      }
    }
    if (matches.length > 6) {
      // Too many cached images, just remove them
      matches.forEach(key => {
        window.sessionStorage.removeItem(key)
      })
    }
  }

  private cacheImage(key: string, value: string) {
    this.ensureCache()
    window.sessionStorage.setItem(key, value)
  }

  private removeImageFromCache(key: string) {
    window.sessionStorage.removeItem(key)
  }

  private getImageFromCache(key: string): string {
    return window.sessionStorage.getItem(key) || ''
  }

  private async generateImageAssetFromDataUri(
    blob: Blob,
    name: string,
    contentType: string,
    res: number
  ): Promise<ImageAsset> {
    const asset: ImageAsset = {
      uuid: '',
      name: name,
      contentType: contentType,
      dataUri: ''
    }
    try {
      asset.dataUri = await filetoDataURL(await downscaleImage(blob, res))
    } catch (e) {
      console.error(e)
      asset.dataUri = await filetoDataURL(blob)
    }
    return asset
  }

  private removeImageAssets(id: string) {
    this.removeImageFromCache(`thumb-${id}`)
    this.removeImageFromCache(`orig-${id}`)
    this.thumbnail = {
      uuid: '',
      name: '',
      dataUri: '',
      contentType: ''
    }
    this.original = {
      uuid: '',
      name: '',
      dataUri: '',
      contentType: ''
    }
  }

  private inputFile(newFile: any, oldFile: any) {
    let data = newFile
    if (!data) {
      data = oldFile
    }
    if (!data) return
    this.autoUpload()
  }

  private async processFile(file: File): Promise<ArrayBuffer> {
    let originalBlob: Blob
    if (file.size > this.MAX_UPLOAD_SIZE) {
      originalBlob = await downscaleImage(file, this.UPLOAD_WIDTH, this.MAX_UPLOAD_SIZE_KB)
    } else {
      originalBlob = await downscaleImage(file, this.UPLOAD_WIDTH)
    }

    const thumbnailBlob = await downscaleImage(file, this.THUMBNAIL_WIDTH)
    this.original.dataUri = await filetoDataURL(originalBlob)
    this.thumbnail.dataUri = await filetoDataURL(thumbnailBlob)
    this.thumbnail.name = this.original.name = file.name
    this.thumbnail.contentType = this.original.contentType = file.type

    return removeExifTagsFromJpeg(await blobToArrayBuffer(originalBlob))
  }

  private autoUpload() {
    const upload = this.$refs.upload as any
    if (!upload.active) {
      upload.active = true
    }
  }

  private inputFilter(newFile: any, oldFile: any, prevent: Function) {
    if (newFile) {
      // Filter non-image file
      // Will not be added to files
      if (!/\.(jpeg|jpe|jpg)$/i.test(newFile.name)) {
        this.$showErrorPopUp(this.$t('fact.error.notJpeg').toString())
        return prevent()
      }
    }
  }

  private remove() {
    Shift.removeFile(this.fact.currentValue).then(() => {
      this.$emit('fact-change', {
        id: this.fact.id,
        value: ''
      } as FactChangeEvent)
    })
    if (this.isOcr) {
      // eslint-disable-next-line
      for (const [_, value] of Object.entries(Config.ocr.factOcrMap)) {
        this.$emit('fact-change', {
          id: value,
          value: ''
        } as FactChangeEvent)
      }
    }
    this.removeImageAssets(this.fact.currentValue)
  }

  private openInfo(e: Event) {
    console.log(this.description)
    e.preventDefault()

    this.checkCameraPermission(() => {
      if (this.description) {
        this.$modal.show(this.description.name)
      }
    })
  }

  openImage() {
    this.$viewImage(this.original.name, this.original.dataUri)
  }

  activeCamera() {
    (this.$refs.infoModal as any).onCancel();
    (this.$refs.uploadLabel as any).click()
  }

  async uploadAction(data: any) {
    try {
      const file = data.file as File
      const buffer = await this.processFile(file)
      const content = await Shift.uploadFile({
        fileType: file.type,
        fileName: encodeURIComponent(file.name),
        file: buffer,
        contextId: ''
      })

      if (content) {
        this.thumbnail.uuid = this.original.uuid = content.id
        this.cacheImage(`thumb-${content.id}`, this.thumbnail.dataUri)
        this.cacheImage(`orig-${content.id}`, this.original.dataUri)
        this.$emit('fact-change', {
          id: this.fact.id,
          value: content.id
        } as FactChangeEvent)
        if (this.isOcr) {
          await this.ocrAction(content.id)
        }
      }
    } catch (e) {
      console.error(e)
      this.thumbnail.dataUri = ''
      this.$showErrorPopUp(this.$t('fact.error.uploadFailed').toString())
    }
  }

  // On failure, system_exception is thrown
  async ocrAction(id: string) {
    const ocrTextMap: OCRMap = await Shift.ocrWorkflow(id)
    for (let [key, value] of Object.entries(ocrTextMap)) {
      const id = Config.ocr.factOcrMap[key]
      value = value || ''
      if (id) {
        this.$emit('fact-change', { id, value } as FactChangeEvent)
      }
    }
  }

  mounted() {
    this.getThumbnail()
  }
}
