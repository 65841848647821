






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class LargeText extends Vue {
  @Prop() label!: string
  @Prop() value!: string
}
