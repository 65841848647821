





























import { Component, Vue, Prop } from 'vue-property-decorator'
import { FactDescription } from '@/interfaces/engine-exports'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'

@Component
export default class RadioButtonGroup extends Vue {
  @Prop()
  fact!: FactDescription

  get radioValue(): string {
    const currentValue = `${this.fact.currentValue}`
    const defaultValue = `${this.fact.defaultValue}`
    return (
      (currentValue.trim() !== '' && currentValue) ||
      (defaultValue.trim() !== '' && defaultValue) ||
      ''
    )
  }

  set radioValue(newValue: string) {
    this.$emit('fact-change', {
      id: this.fact.id,
      value: newValue
    } as FactChangeEvent)
  }

  getLabel() {
    return this.fact.name
  }
}
