



import { Component, Mixins } from 'vue-property-decorator'
import DetailField from '@/components/DetailField.vue'
import FieldMixin from '../FieldMixin.vue'

@Component({
  components: {
    DetailField
  }
})
export default class DetailFieldRenderer extends Mixins(FieldMixin) {}
