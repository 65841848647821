import { FactDescription } from '@/interfaces/engine-exports'
import { DATE_OUTPUT_FORMAT } from '@/services/functions'
import { FactConfigDef } from '@/services/Renderer'
import moment from 'moment'

function dateValue(fact: FactDescription) {
  const val = fact.currentValue || fact.defaultValue
  if (val) {
    const date = moment(val)
    if (date.isValid()) {
      return date.format(DATE_OUTPUT_FORMAT)
    }
    return ''
  }
  return val
}

function priceValue(fact: FactDescription) {
  return Number(fact.currentValue).toLocaleString()
}

function listValue(fact: FactDescription) {
  return fact.options.find((o) => o.value === fact.currentValue)?.name || ''
}

export const FactConfigs: FactConfigDef = {
  default: {
    'COMPONENT:base.guarantee.purchasedate': {
      max() {
        return new Date()
      },
      min() {
        return moment().subtract(1, 'year').add(1, 'day').startOf('day').toDate()
      },
      placeholder: 'YYYY-MM-DD'
    },
    'applicant.dob': {
      min() {
        return moment('1900-01-01').startOf('day').toDate()
      },
      max() {
        return moment().subtract(18, 'year').startOf('day').toDate()
      },
      placeholder: 'YYYY-MM-DD'
    },
    'claim.dateofevent': {
      max() {
        return new Date()
      },
      placeholder: 'YYYY-MM-DD'
    },
    'applicant.lastname': {
      icon: 'profile'
    },
    'applicant.firstname': {
      icon: 'profile'
    },
    'applicant.email': {
      transformValue(value: string): string {
        return value ? value.toLocaleLowerCase().trim() : value
      }
    },
    'COMPONENT:base.deviceregistration.photofront.serialbody': {
      icon: 'pencil',
      transformValue(value: string): string {
        return value.toLocaleUpperCase()
      }
    },
    'COMPONENT:base.deviceregistration.photofront.serialjoyconleft': {
      icon: 'pencil',
      transformValue(value: string): string {
        return value.toLocaleUpperCase()
      }
    },
    'COMPONENT:base.deviceregistration.photofront.serialjoyconright': {
      icon: 'pencil',
      transformValue(value: string): string {
        return value.toLocaleUpperCase()
      }
    },
    'COMPONENT:base.deviceregistration.campaigncode': {
      transformValue(value: string): string {
        return value.toLocaleUpperCase()
      }
    }
  },
  appForm: {
    'COMPONENT:base.deviceregistration.nickname': {
      label: 'appForm.label.deviceNickname'
    },
    'applicant.lastname': {
      icon: 'profile',
      label: 'appForm.label.applicantLastName'
    },
    'applicant.firstname': {
      icon: 'profile',
      label: 'appForm.label.applicantFirstName'
    },
    'applicant.dob': {
      label: 'appForm.label.dateOfBirth',
      min() {
        return moment('1900-01-01').startOf('day').toDate()
      },
      max() {
        return moment().subtract(18, 'year').startOf('day').toDate()
      }
    }
  },
  proofCert: {
    'COMPONENT:base.guarantee.receipt': {
      label: 'proofCert.label.receipt'
    }
  },
  claimSummary: {
    'claim.type': {
      value: listValue
    },
    'claim.issue': {
      value: listValue
    },
    'claim.dateofevent': {
      value: dateValue
    },
    'device.type': {
      value(fact: FactDescription, translate: Function) {
        return translate(`deviceIcon.mapping.${fact.currentValue}`)
      }
    },
    'virtual.repairDate': {
      value: dateValue,
      label: 'fact.virtual.repairDate.name'
    },
    'virtual.repairTimesLastOneYear': {
      label: 'fact.virtual.repairTimesLastOneYear.name'
    },
    'virtual.amortizeRepairCost': {
      label: 'fact.virtual.amortizeRepairCost.name',
      value(fact: FactDescription, translate: Function) {
        return translate('common.currencySymbol', { amount: priceValue(fact) })
      }
    }
  },
  deviceSummary: {
    'COMPONENT:base.plan.plan': {
      label: 'summary.label.plan',
      value: listValue
    },
    'applicant.dob': {
      value: dateValue
    },
    'COMPONENT:base.guarantee.purchasedate': {
      value: dateValue
    }
  },
  claimDetail: {
    'claim.issue.other': {
      label: 'claimDetail.label.otherIssue'
    },
    'device.type': {
      value(fact: FactDescription, translate: Function) {
        return translate(`deviceIcon.mapping.${fact.currentValue}`)
      }
    },
    'virtual.repairDate': {
      value: dateValue,
      label: 'fact.virtual.repairDate.name'
    },
    'virtual.claimNumber': {
      label: 'fact.virtual.claimNumber.name'
    },
    'virtual.name': {
      label: 'fact.virtual.name.name'
    },
    'virtual.address': {
      label: 'fact.virtual.address.name'
    },
    'outcome.shippingDate': {
      value: dateValue
    },
    'outcome.shippingDateOfFinishedGoods': {
      value: dateValue
    },
    'outcome.arrivalDateOfCompletedGoods': {
      value: dateValue
    },
    'outcome.cost.totalCost': {
      value(fact: FactDescription, translate: Function) {
        return translate('common.currencySymbol', { amount: priceValue(fact) })
      }
    },
    'virtual.deliveryTrackingNumber': {
      label: 'fact.virtual.deliveryTrackingNumber.name',
      value(fact: FactDescription) {
        return fact.currentValue?.split('').reduce((previous, current, index) => previous + (index !== 0 && index % 4 === 0 ? '-' + current : current), '')
      }
    },
    'virtual.returnTrackingNumber': {
      label: 'fact.virtual.returnTrackingNumber.name',
      value(fact: FactDescription) {
        return fact.currentValue?.split('').reduce((previous, current, index) => previous + (index !== 0 && index % 4 === 0 ? '-' + current : current), '')
      }
    },
    'virtual.repairComments': {
      label: 'fact.virtual.repairComments.name'
    },
    'virtual.status': {
      label: 'fact.virtual.status.name',
      value(fact: FactDescription, translate: Function) {
        const value = fact.currentValue || fact.defaultValue
        const option = fact.options.find((o) => o.value === value)
        return option
          ? translate(`fact.virtual.status.values.${option.name}`)
          : ''
      }
    },
    'virtual.repairTimesLastOneYear': {
      label: 'fact.virtual.repairTimesLastOneYear.name'
    },
    'virtual.amortizeRepairCost': {
      label: 'fact.virtual.amortizeRepairCost.name',
      value(fact: FactDescription, translate: Function) {
        return translate('common.currencySymbol', { amount: priceValue(fact) })
      }
    }
  },
  claimShipping: {
    'claimant.lastnamekanji': {
      label: 'claimShipping.labels.lastNameKanji'
    },
    'claimant.firstnamekanji': {
      label: 'claimShipping.labels.firstNameKanji'
    }
  }
}
