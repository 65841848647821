



import { Component, Mixins } from 'vue-property-decorator'
import DetailField from '@/components/DetailField.vue'
import FieldMixin from '../FieldMixin.vue'

@Component({
  components: {
    DetailField
  }
})
export default class DeviceTypeRenderer extends Mixins(FieldMixin) {
  get value(): string {
    return this.$t(`fact.deviceType.${this.fact.currentValue}`).toString()
  }
}
