









import { Component, Mixins, Prop } from 'vue-property-decorator'
import Multiline from '@/components/facts/Multiline.vue'
import FieldMixin from '@/components/facts/FieldMixin.vue'

@Component({
  components: {
    Multiline
  }
})
export default class ClaimDetailOtherRenderer extends Mixins(FieldMixin) {
  @Prop() regex?: RegExp
}
