

















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import TextInput from '@/components/TextInput.vue'
import { FactDescription } from '@/interfaces/engine-exports'
import { FactConfig, ValidationResult } from '@/services/Renderer'
import { FactValidationEvent } from '@/interfaces/FactValidationEvent'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import ImportantInfo from '@/components/ImportantInfo.vue'
import { Shift } from '@/services/Shift'

@Component({
  components: {
    ImportantInfo,
    TextInput
  }
})
export default class DeviceNicknameRenderer extends Vue {
  @Prop() fact!: FactDescription
  @Prop() config?: FactConfig
  @Prop({ default: false }) readonly?: boolean

  protected error = false
  private nicknamePlaceholder = ''

  get label(): string {
    if (this.config?.label) {
      return this.$t(this.config.label).toString()
    } else {
      return this.fact.name
    }
  }

  get value(): string {
    if (this.config?.value) {
      return this.config.value(this.fact, this.$t.bind(this))
    } else {
      return this.fact.currentValue || this.fact.defaultValue || ''
    }
  }

  get disabled(): boolean {
    if (this.readonly) {
      return this.readonly
    } else {
      return this.fact.readonly
    }
  }

  async mounted() {
    if (this.value) {
      this.validateInput(this.value)
      this.validateChange(this.value)
    }

    this.nicknamePlaceholder = await Shift.generateDeviceNickname(this.$t('common.devicePrefix').toString())
  }

  protected validateInput(value: string): ValidationResult {
    let validationResult: ValidationResult = { valid: true }

    if (this.config?.validate) {
      validationResult = this.config.validate(value, this.fact)
    }

    this.fireFactValidation({
      id: this.fact.id,
      valid: validationResult.valid,
      message: validationResult.message
    })
    this.error = !validationResult.valid

    return validationResult
  }

  protected validateChange(value: string): ValidationResult {
    let validationResult: ValidationResult = { valid: true }

    if (this.config?.validateChange) {
      validationResult = this.config.validateChange(value, this.fact, this)
      this.fireFactValidation({
        id: this.fact.id,
        valid: validationResult.valid,
        message: validationResult.message
      })
      this.error = !validationResult.valid
    }
    return validationResult
  }

  onInput(value: string) {
    this.validateInput(value)
    this.fireFactChange({
      id: this.fact.id,
      value: value
    })
  }

  onChange(value: string) {
    this.validateChange(value)
    this.fireFactChange({
      id: this.fact.id,
      value: value
    })
  }

  onValidation(valid: boolean) {
    this.fireFactValidation({
      id: this.fact.id,
      valid
    })
  }

  @Emit('fact-validation')
  fireFactValidation(evt: FactValidationEvent) {
    return evt
  }

  @Emit('fact-change')
  fireFactChange(evt: FactChangeEvent) {
    return evt
  }
}
