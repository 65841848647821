


















import { Component, Vue, Prop } from 'vue-property-decorator'
import vSelect from 'vue-select'

@Component({
  name: 'Select',
  components: {
    vSelect
  }
})
export default class Select extends Vue {
  @Prop({ required: true })
  label!: string

  @Prop({ default: '' })
  placeholder!: string

  @Prop({ required: true })
  options!: SelectOption[]
}

export interface SelectOption {
  value: any;
  description: string;
}
