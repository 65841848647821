



import { Component, Mixins } from 'vue-property-decorator'
import DetailField from '@/components/DetailField.vue'
import FieldMixin from '@/components/facts/FieldMixin.vue'
@Component({
  components: {
    DetailField
  }
})
export default class ClaimStatusFieldRenderer extends Mixins(FieldMixin) {
  get value(): string {
    if (this.fact.currentValue) {
      return this.$t(`customStatusFieldRenderer.status.${this.fact.currentValue}`).toString()
    }
    return ''
  }
}
