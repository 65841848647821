



import { Component, Vue, Prop } from 'vue-property-decorator'
import DetailField from '@/components/DetailField.vue'
import { FactDescription } from '@/interfaces/engine-exports'
@Component({
  components: {
    DetailField
  }
})
export default class ListDetailFieldRenderer extends Vue {
  @Prop()
  fact!: FactDescription

  factValue(fact: FactDescription): string {
    const value = fact.currentValue || fact.defaultValue
    const option = fact.options.find(o => o.value === value)
    return option ? option.name : ''
  }
}
