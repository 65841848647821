






























import { Component, Vue, Prop } from 'vue-property-decorator'
import { FactDescription } from '@/interfaces/engine-exports'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'

/**
 * Renders plan option
 */
@Component
export default class PlanOptionRenderer extends Vue {
  @Prop()
  fact!: FactDescription

  planSelected (value: string) {
    this.$emit('fact-change', {
      id: this.fact.id,
      value,
      next: true
    } as FactChangeEvent)
  }
}
