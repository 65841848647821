









import { Component, Vue, Prop } from 'vue-property-decorator'
import DetailField from '@/components/DetailField.vue'
import { FactDescription } from '@/interfaces/engine-exports'
@Component({
  components: {
    DetailField
  }
})
export default class JoyConDetailFieldRenderer extends Vue {
  @Prop()
  fact!: FactDescription

  factValue(fact: FactDescription): string {
    return fact.currentValue || fact.defaultValue || ''
  }
}
