

























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { FactDescription, ValidationError } from '@/interfaces/engine-exports'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import { FactConfig, Renderer } from '@/services/Renderer'
import { FactValidationEvent } from '@/interfaces/FactValidationEvent'

@Component({
  components: Renderer.components
})
export default class Fact extends Vue {
  @Prop() fact!: FactDescription
  @Prop() validationErrors?: ValidationError[]
  @Prop({ default: '' }) renderer!: string
  @Prop({ default: false }) disableValidations!: boolean
  @Prop() context?: string
  @Prop({ default: false }) readonly?: boolean
  private factRenderer = ''
  private factConfig: FactConfig | undefined
  private factValidationEvt: undefined | FactValidationEvent

  created() {
    if (this.renderer === '') {
      const renderer = Renderer.get(this.fact, this.context)
      this.factRenderer = renderer || Renderer.DEFAULT
    } else {
      this.factRenderer = this.renderer
    }
    this.factConfig = Renderer.config.get(this.fact, this.context)
  }

  get isInvalid(): boolean {
    return this.validations.length > 0
  }

  get shouldRender(): boolean {
    return this.fact.type.toString() !== 'GROUP'
  }

  get validations(): string[] {
    if (!this.validationErrors) {
      return []
    }
    const response: string[] = []
    this.validationErrors.forEach((v: ValidationError) => {
      if (v.relatedFacts.includes(this.fact.id)) {
        let errorMessage = null
        if (this.$te(`${this.factRenderer}.fact.error.${v.message}`)) {
          errorMessage = `${this.factRenderer}.fact.error.${v.message}`
        } else if (this.$te(`fact.error.${v.message}`)) {
          errorMessage = `fact.error.${v.message}`
        } else {
          errorMessage = v.message
        }
        response.push(errorMessage)
      }
    })
    if (
      this.factValidationEvt?.valid === false &&
      this.factValidationEvt.message
    ) {
      const msg = this.factValidationEvt.message
      response.push(
        this.$te(`fact.error.${msg}`)
          ? `fact.error.${msg}` : msg
      )
    }

    return response
  }

  onFactChange(evt: FactChangeEvent) {
    this.$emit('fact-change', evt)
  }

  @Emit('fact-validation')
  fireFactValidation(evt: FactValidationEvent) {
    this.factValidationEvt = evt
    return evt
  }
}
