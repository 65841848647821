
import { Component, Mixins } from 'vue-property-decorator'
import FieldMixin from '@/components/facts/FieldMixin.vue'
import debounce from 'lodash.debounce'

@Component
export default class PostalCodeRenderer extends Mixins(FieldMixin) {
  onInput(value: string) {
    this.validateInput(value)
    if (value && value.length === 7) {
      this.fireFactChange({
        id: this.fact.id,
        value: value
      })
    }
  }

  onChange(value: string) {
    this.validateChange(value)
    if (value) {
      this.fireFactChange({
        id: this.fact.id,
        value: value
      })
    }
  }

  created() {
    const DEBOUNCE_TIMEOUT = 200
    this.onInput = debounce(this.onInput.bind(this), DEBOUNCE_TIMEOUT)
  }
}
